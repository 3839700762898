import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import ContactForm from '../components/Forms/ContactForm'
import ContactMap from '../components/ContactMap'
import HeroSingle from '../components/HeroSingle'
import SEO from '../components/SEO/index'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    const {
      frontmatter: { title },
      html,
    } = data.page

    return (
      <Layout location={location}>
        <SEO
          path={location.pathname}
          frontmatter={data.page.frontmatter}
          description={data.page.excerpt}
        />
        <HeroSingle data={data.page} />
        <div class="bg-offwhite py-5">
          <div
            className="body mini-container c-privacy-policy"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Layout>
    )
  }
}

export default Index

export const ContactQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { id: { eq: "privacy_policy" } }) {
      ...HeroSingle
      ...SEO
      frontmatter {
        title
      }
      html
    }
  }
`
